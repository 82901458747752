@import url("https://fonts.googleapis.com/css?family=Lexend");

/* From Uiverse.io by G4b413l */
.three-body {
  --uib-size: 35px;
  --uib-speed: 0.8s;
  --uib-color: #5d3fd3;
  position: relative;
  display: inline-block;
  height: var(--uib-size);
  width: var(--uib-size);
  animation: spin78236 calc(var(--uib-speed) * 2.5) infinite linear;
}

.three-body__dot {
  position: absolute;
  height: 100%;
  width: 30%;
}

.three-body__dot:after {
  content: "";
  position: absolute;
  height: 0%;
  width: 100%;
  padding-bottom: 100%;
  background-color: var(--uib-color);
  border-radius: 50%;
}

.three-body__dot:nth-child(1) {
  bottom: 5%;
  left: 0;
  transform: rotate(60deg);
  transform-origin: 50% 85%;
}

.three-body__dot:nth-child(1)::after {
  bottom: 0;
  left: 0;
  animation: wobble1 var(--uib-speed) infinite ease-in-out;
  animation-delay: calc(var(--uib-speed) * -0.3);
}

.three-body__dot:nth-child(2) {
  bottom: 5%;
  right: 0;
  transform: rotate(-60deg);
  transform-origin: 50% 85%;
}

.three-body__dot:nth-child(2)::after {
  bottom: 0;
  left: 0;
  animation: wobble1 var(--uib-speed) infinite calc(var(--uib-speed) * -0.15)
    ease-in-out;
}

.three-body__dot:nth-child(3) {
  bottom: -5%;
  left: 0;
  transform: translateX(116.666%);
}

.three-body__dot:nth-child(3)::after {
  top: 0;
  left: 0;
  animation: wobble2 var(--uib-speed) infinite ease-in-out;
}

@keyframes spin78236 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes wobble1 {
  0%,
  100% {
    transform: translateY(0%) scale(1);
    opacity: 1;
  }

  50% {
    transform: translateY(-66%) scale(0.65);
    opacity: 0.8;
  }
}

@keyframes wobble2 {
  0%,
  100% {
    transform: translateY(0%) scale(1);
    opacity: 1;
  }

  50% {
    transform: translateY(66%) scale(0.65);
    opacity: 0.8;
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0); /* Starting and ending position */
  }
  50% {
    transform: translateY(-20px); /* Bounce upward */
  }
}

.animated-icon {
  animation: moveIcon 5s infinite alternate, spinIcon 8s infinite linear;
}

@keyframes moveIcon {
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translate(30px, 30px); /* Move the icons slightly */
  }
}

@keyframes spinIcon {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.icon-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.x {
  animation: x 5.2s linear infinite alternate;
}

.y {
  animation: y 1.8s linear infinite alternate;
}

@keyframes x {
  100% {
    transform: translateX( calc(100vw - 100px) );
  }
}

@keyframes y {
  100% {
    transform: translateY( calc(100vh - 100px) );
  }
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
}

.icon {
  animation: float 16s ease-in-out infinite;
  animation-delay: calc(var(--uib-speed) * -0.3);
  animation-direction: alternate;
  animation-timing-function: ease-in-out;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  animation-name: float;
  animation-duration: 16s;
  animation-play-state: running;
  /* width: 40px; */
  height: 40px;
  position: absolute;
  /* top: 0;
  left: 0; */
}