/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

.scrollbar-thin::-webkit-scrollbar {
    width: 8px;
  }
  .scrollbar-thin::-webkit-scrollbar-thumb {
    background-color: #4b5563; /* gray-600 */
    border-radius: 4px;
  }
  .scrollbar-thin::-webkit-scrollbar-track {
    background-color: #1f2937; /* gray-800 */
  }
